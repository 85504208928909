import { Connection, PublicKey } from "@solana/web3.js";

const METADATA_PREFIX = "metadata";
const METADATA_PROGRAM_ID = "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s";

let connection = new Connection(
  "https://api.mainnet-beta.solana.com",
  "confirmed"
);

export default async function getTokenDataFromWallet(walletAddress) {
  let tokenAddresses = await getTokenAddressesInWallet(walletAddress);
  let tokenData = await getMultipleTokenData(tokenAddresses);
  let tokens = [];
  for (let i = 0; i < tokenData.length; i++)
    if (checkSnkr(tokenData[i])) tokens[i] = await decodeMetadata(tokenData[i]);

  return tokens;
}

async function getTokenAddressesInWallet(walletAddress) {
  let response = await connection
    ._rpcRequest("getTokenAccountsByOwner", [
      walletAddress,
      { programId: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA" },
      { encoding: "jsonParsed" },
    ])
    .then((e) => e.result.value);

  return response
    .map((e) => e.account.data.parsed.info)
    .filter((e) => e.tokenAmount.amount == "1")
    .map((e) => e.mint);
}

async function getMultipleTokenData(tokens, from = 0, to = tokens.length) {
  let metadataAddresses = [];
  for (let i = from; i < to; i++)
    metadataAddresses[i] = await getMetadataAddress(tokens[i]);
  let accountInfos = await connection.getMultipleAccountsInfo(
    metadataAddresses
  );

  return accountInfos.map((e) => e.data);
}

function checkSnkr(data) {
  let symbol = data.slice(105, 115).toString().replace(/\0/g, "");
  let authority = PublicKey.from(data.slice(1, 33));
  return (
    symbol == "SNKR" &&
    authority.toBase58() == "569pkjHmPedixA2NtLuMZsGPVdN1mKR3uiS1BANp4zj"
  );
}

async function decodeMetadata(data) {
  let metadataUri = data.slice(119, 319).toString().replace(/\0/g, "");
  if (!metadataUri) return null;
  let json = await fetch(metadataUri).then((e) => e.json());
  json.name = data.slice(69, 101).toString().replace(/\0/g, "");
  return json;
}

async function getMetadataAddress(tokenAddress) {
  let metadataProgramIdKey = PublicKey.from(METADATA_PROGRAM_ID);
  let [address, nonce] = await PublicKey.findProgramAddress(
    [
      stringToBuffer(METADATA_PREFIX),
      metadataProgramIdKey.toBuffer(),
      PublicKey.from(tokenAddress).toBuffer(),
    ],
    metadataProgramIdKey
  );

  return address;
}

const PUBLIC_KEY_CACHE = {};
PublicKey.from = (input) => {
  let publicKey = null;
  if (typeof input == "string" || input instanceof Uint8Array)
    publicKey = new PublicKey(input);
  else throw new Error(`Could not create PublicKey from: "${input}"`);

  let key = publicKey.toBase58();
  if (!(key in PUBLIC_KEY_CACHE)) PUBLIC_KEY_CACHE[key] = publicKey;
  return PUBLIC_KEY_CACHE[key];
};

function stringToBuffer(string) {
  return Uint8Array.from(string, (e) => e.charCodeAt(0));
}
