import React from "react";

import { Box, Grid, FormControlLabel, Checkbox } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ReactPlayer from 'react-player'
import getTokenDataFromWallet from "./loadWalletNfts";
import { useWallet } from "@solana/wallet-adapter-react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
function Usage() {
  const { publicKey } = useWallet();

  let [tokens, setTokens] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      if (!publicKey) {
        setTokens([]);
        return;
      }

      let tokens = await getTokenDataFromWallet(publicKey.toBase58());
      setTokens(tokens);
    })();
  }, [publicKey, useWallet]);


  return (<>
  {
    publicKey ?
    <>
    {
      tokens.length > 0 ?
      <>
          <Box sx={{ flexGrow: 1, mt: "2rem"}}>
      <Grid container style={{width:'100%'}} spacing={2}>
        {tokens.map((nft, index) => (
          <Grid  className="each__row" key={index} item xs={12} md={4} >
            <Card  className="bkg">
             <div className="img__container">
              {    
                nft.properties.files[1] ?
                <>
                 {
                   nft.properties.files[1].type === "video/quicktime" || nft.properties.files[1].type === "video/mp4" ?
                   <>
             {/* <video width="100%" height="50%" autoPlay loop>
               <source src={nft.properties.files[1].uri} type="video/mp4"/>
             </video> */}
             <ReactPlayer url={nft.properties.files[1].uri} style={{textAlign:'center'}}  width="100%" height="50%" className="img__size"  loop playing={true} />
                   </>:
                     <img src={nft.properties.files[1].uri} alt="" style={{textAlign:'center'}} width="96%" height="50%"  className="img__size"  style={{ borderRadius: 20 }}/>
                 }

              </>
                :
                <>
               
                    <img src={nft.properties.files[0].uri} alt="" style={{textAlign:'center'}} width="96%" height="50%"  className="img__size"  style={{ borderRadius: 20 }}/>
             
             
                </>
           
              }
              </div>
              <CardContent className="content" style={{textAlign:'center'}}>
                <Grid container style={{width:'100%'}} spacing={2}>
                  <Grid item xs={12} md={12}>
                <Typography className="text__name" gutterBottom variant="h6" component="div">
                  {nft.name}
                </Typography>
                <Typography className="text__descp" variant="body2" color="text.secondary">
                  {nft.description}
                </Typography>
                </Grid>

                <Grid item xs={12} md={12}>
                <table>
                 
                  {
                    nft.attributes.map((attr,index)=>(
                      <>
                  <tr key={index}>
                  <td>{attr.trait_type}</td>
                  <td>{attr.value}</td>
                </tr>
                      </>
                    ))
                  }

                </table>
                </Grid>
                </Grid>
              </CardContent>
             

            </Card>
     
          </Grid>
        ))}
      </Grid>
    </Box>
      </>:
            <Loader
            type="ThreeDots"
            color="#FFF"
            height={200}
            width={200}

            />
    }

    </>
    :<h1 className="heading">PLEASE CONNECT WALLET TO VIEW YOUR NFTs</h1>
  }
    
  </>);
}

export default Usage;
