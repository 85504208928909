import "./App.css";
import Usage from "./Usage";
import Wallet from "./Wallet/Wallet";

function App() {
  return (
    <div className="App">
      <h1 className="heading">Your NFTs</h1>
      <Wallet />

    </div>
  );
}

export default App;
