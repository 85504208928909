import React, { FC, useEffect, useMemo } from "react";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getLedgerWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
  getTorusWallet,
} from "@solana/wallet-adapter-wallets";

import { useWallet } from "@solana/wallet-adapter-react";
import {
  WalletModalProvider,
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl } from "@solana/web3.js";
import "./Wallet.css";
import { Grid, Box } from "@mui/material";

import Usage from "../Usage";

// Default styles that can be overridden by your app
require("@solana/wallet-adapter-react-ui/styles.css");
function Wallet() {
  // Can be set to 'devnet', 'testnet', or 'mainnet-beta'

  const network = WalletAdapterNetwork.Mainnet;

  // You can also provide a custom RPC endpoint
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking --
  // Only the wallets you configure here will be compiled into your application
  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getLedgerWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    [network]
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <div>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid className="wallet__btn" item xs={12} md={4}>
                  <WalletMultiButton
                    className="wallet__select"
                    style={{ backgroundColor: "#75e6e4", color: "#333" }}
                  />
                 
                </Grid>
                <Grid style={{textAlign:'center'}} item xs={12} md={12}>
                  <Usage/>
                </Grid>
              </Grid>
            </Box>
          </WalletModalProvider>
        </WalletProvider>
      </div>
    </ConnectionProvider>
  );
}
export default Wallet;
